import React, { Component } from "react";
import {BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./component/Auth/Login";
import User from "./component/User/User";
import UserPlatinum from "./component/User/UserPlatinum";
import Dashboard from "./component/Dashboard/Dashboard";
import { AuthProvider, RequireAuth, useIsAuthenticated } from "react-auth-kit";
import WithHold from "./component/Auth/WithHold";
import Broker from "./component/Setting/Broker/Broker";
import Commission from "./component/User/commission/Commission";
import FromEditAgent from "./component/User/formEdit/FormEditAgent";
import FormEditUser from "./component/User/FormEditUser";
import Profile from "./component/Profile/Profile";
import Credit from "./component/Credit/Credit";
import InsureCMI from "./component/CMI/InsureCMI";
import InsureVMI from "./component/VMI/InsureVMI";
import EditBroker from "./component/Setting/Broker/EditBroker";
import FormEditCommission from "./component/User/commission/FormEditCommission";
import UserGold from "./component/User/UserGold";
import UserAdministrator from "./component/User/UserAdministrator";
import UserSilver from "./component/User/UserSilver";
import UserLevel from "./component/User/UserLevel";
import ReportCommission from "./component/ReportCommission/ReportCommission";
import RedeemCommission from "./component/RedeemCommission/RedeemCommission";
import DetailCommission from "./component/RedeemCommission/Detailcommission";
import ManageInsurance from "./component/ManageInsurance/ManageInsurance";
import CancleInsure from "./component/CancleInsure/CancleInsure";
import Test from "./component/Test/Test"

const PrivateRoute = ({ Component }) => {
  const isAuthenticated = useIsAuthenticated();
  const auth = isAuthenticated();
  return auth ? <Component /> : <Navigate to="/" />;
};

class App extends Component {
  renderRouter() {
    return (

      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/dashboard' element={<PrivateRoute Component={Dashboard} />} />
        {/* แสดงผู้ใช้ */}
        <Route path='/user' element={<PrivateRoute Component={User} />} />
        <Route path='/administrator' element={<PrivateRoute Component={UserAdministrator} />} />
        <Route path='/manageUser' element={<PrivateRoute Component={UserLevel} />} />
        <Route path='/manageUserPlatinum' element={<PrivateRoute Component={UserPlatinum} />} />
        <Route path='/manageUserGold' element={<PrivateRoute Component={UserGold} />} />
        <Route path='/manageUserSilver' element={<PrivateRoute Component={UserSilver} />} />
        {/* แก้ไขรายละเอียดของ Agent */}
        <Route path='/edit/:role/:id' element={<PrivateRoute Component={FormEditUser} />} />
        {/* จัดการ Commission ของ User */}
        <Route path='/commission/:id' element={<PrivateRoute Component={Commission} />} />
        {/* จัดการ Commission ของ User */}
        <Route path='/commissionEdit/:userId/:brokerId' element={<PrivateRoute Component={FormEditCommission} />} />
        {/* จัดการ Broker */}
        <Route path='/broker' element={<PrivateRoute Component={Broker} />} />
        {/* จัดการกรมธรรม์ */}
        <Route path='/manageInsurance' element={<PrivateRoute Component={ManageInsurance} />} />
        {/* ยกเลิกกรมธรรม์ */}
        <Route path='/cancleinsurance' element={<PrivateRoute Component={CancleInsure} />} />
        {/* แก้ไข Broker */}
        <Route path='/editBroker/:id' element={<PrivateRoute Component={EditBroker} />} />
        {/* จัดการข้อมูลส่วนตัว */}
        <Route path='/profile' element={<PrivateRoute Component={Profile} />} />
        {/* จัดการเครดิต */}
        <Route path='/credit' element={<PrivateRoute Component={Credit} />} />
        {/* สั่งซื้อ พรบ. */}
        <Route path='/insurecmi' element={<PrivateRoute Component={InsureCMI} />} />
        {/* สั่งซื้อประกัน */}
        <Route path='/insurevmi' element={<PrivateRoute Component={InsureVMI} />} />
        {/* รายงานคอมมิชชั่น */}
        <Route path='/reportcommission' element={<PrivateRoute Component={ReportCommission} />} />
        {/* รายงานคืนคอมมิชชั่น */}
        <Route path='/redeemcommission' element={<PrivateRoute Component={RedeemCommission} />} />
        <Route path='/detailcommission' element={<PrivateRoute Component={DetailCommission} />} />
        <Route path='/403' element={<WithHold />} />
        <Route path='/test' element={<Test />} />
        {/* <Route path='*' element={<NotFound />} /> */}
      </Routes>

      // <Routes>
      //   <Route path='/login' element={<Login />} />
      //   <Route path='/dashboard' element={<RequireAuth loginPath="/login">
      //     <Dashboard/>
      //   </RequireAuth>} />
      //   {/* แสดงผู้ใช้ */}
      //   <Route path='/user' element={<RequireAuth loginPath="/login">
      //     <User/>
      //   </RequireAuth>} />
      //   <Route path='/administrator' element={<RequireAuth loginPath="/login">
      //     <UserAdministrator/>
      //   </RequireAuth>} />
      //   <Route path='/manageUser' element={<RequireAuth loginPath="/login">
      //     <UserLevel/>
      //   </RequireAuth>} />
      //   <Route path='/manageUserPlatinum' element={<RequireAuth loginPath="/login">
      //     <UserPlatinum />
      //   </RequireAuth>} />
      //   <Route path='/manageUserGold' element={<RequireAuth loginPath="/login">
      //     <UserGold />
      //   </RequireAuth>} />
      //   <Route path='/manageUserSilver' element={<RequireAuth loginPath="/login">
      //     <UserSilver />
      //   </RequireAuth>} />
      //   {/* แก้ไขรายละเอียดของ Agent */}
      //   <Route path='/edit/:role/:id' element={<RequireAuth loginPath="/login">
      //     <FormEditUser />
      //   </RequireAuth>} />
      //   {/* จัดการ Commission ของ User */}
      //   <Route path='/commission/:id' element={<RequireAuth loginPath="/login">
      //     <Commission />
      //   </RequireAuth>} />
      //   {/* จัดการ Commission ของ User */}
      //   <Route path='/commissionEdit/:userId/:brokerId' element={<RequireAuth loginPath="/login">
      //     <FormEditCommission />
      //   </RequireAuth>} />
      //   {/* จัดการ Broker */}
      //   <Route path='/broker' element={<RequireAuth loginPath="/login">
      //     <Broker />
      //   </RequireAuth>} />
      //   {/* แก้ไข Broker */}
      //   <Route path='/editBroker/:id' element={<RequireAuth loginPath="/login">
      //     <EditBroker />
      //   </RequireAuth>} />
      //   {/* จัดการข้อมูลส่วนตัว */}
      //   <Route path='/profile' element={<RequireAuth loginPath="/login">
      //     <Profile />
      //   </RequireAuth>} />
      //   {/* จัดการเครดิต */}
      //   <Route path='/credit' element={<RequireAuth loginPath="/login">
      //     <Credit />
      //   </RequireAuth>} />
      //   {/* สั่งซื้อ พรบ. */}
      //   <Route path='/insurecmi' element={<RequireAuth loginPath="/login">
      //     <InsureCMI />
      //   </RequireAuth>} />
      //   {/* สั่งซื้อประกัน */}
      //   <Route path='/insurevmi' element={<RequireAuth loginPath="/login">
      //     <InsureVMI />
      //   </RequireAuth>} />
      //   <Route path='/403' element={<WithHold />} />
      //   {/* <Route path='*' element={<NotFound />} /> */}
      // </Routes>
    
      
    );
  }
  
  render() {
    return (
      <BrowserRouter>
        <AuthProvider authType = {'cookie'}
                    authName={'_auth'}
                    cookieDomain={window.location.hostname}
                    cookieSecure={false}>
          {this.renderRouter()}
        </AuthProvider>
      </BrowserRouter>  
    );
  }
}

export default App;
